export const searchMixin = {
	data(){
		return {
			search_input_ : null,
		}
	},
	watch:{
		'search_input_':{
			handler(newVal){
				if(newVal){
					this.searchWithTimer_()
				}
			},
			immediate : true,
		},
	},
	methods:{
		searchWithTimer_(second = 1){
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this.searchData_()
			}, 1000 * second);
		},
	}
}