<template>
	<div class="fill-height fill-width">
		<v-container
			fluid 
			class="pa-0" 
			style="height: calc(100vh - 60px);" 
		>
			<div
				class="px-10 pt-10 fill-height fill-width"
			>
				<v-row 
					no-gutters
				>
					<v-col cols="12" md="auto" class="title mb-1">
						<span
							@click="$router.push('/new-order')"
						>
							Vehicle Information
						</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span
							@click="$router.push('/new-order/select-seat')"
						>
							Select Seat Model
						</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="()=>{backToCustomizationPage(true)}">Customize Seat</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="()=>{$router.push('/new-order/order-options')}">Options</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="()=>{$router.push('/new-order/view-2d')}">View 2D</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span @click="()=>{backToCustomizationPage(false)}">View 3D</span>
					</v-col>
					<v-col cols="12" md="auto" class="title mb-1">
						<span class="mx-2">
							<v-icon size="18" color="secondary">mdi-arrow-left</v-icon>
						</span>
						<span class="black--text">Order Summary</span>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<span
							v-if="$authUser.isCustomer() || $authUser.isShowroom()"
							class="float-right blue--text font-weight-bold"
						>Total: {{ priceInfo.currency ? priceInfo.currency.iso_code : "MYR" }} {{ priceInfo.total_price }}</span>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="10">
						<v-row>
							<v-col cols="12" md="5" class="py-0">
								<v-row
									v-if="selectedSeatModel"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Vehicle Information</span>
										<p class="font-weight-black mt-3 pb-1">
											- {{ (selectedSeatModel.maker ? selectedSeatModel.maker.name + ' ' : '') + (selectedSeatModel.model_name ? selectedSeatModel.model_name + ' ' : '') + (selectedSeatModel.year ? selectedSeatModel.year : '') }}
										</p>
										<v-divider></v-divider>
									</v-col>
								</v-row>
								<v-row
									v-if="selectedSeatModel"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>DK Code</span>
										<p class="font-weight-black mt-3 pb-1">
											- {{ selectedSeatModel.dk_code ? selectedSeatModel.dk_code : '' }}
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row 
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Package</span>
										<span
											v-if="$authUser.isCustomer() || $authUser.isShowroom()"
											class="float-right blue--text"
										>{{ priceInfo.currency ? priceInfo.currency.iso_code : "MYR" }} {{ priceInfo.base_price }}</span>
										<p class="font-weight-black mt-3 pb-1">
											- {{ orderCustomizations.package ? orderCustomizations.package : '' }}
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row 
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Main Color</span>
										<span
											v-if="$authUser.isCustomer() || $authUser.isShowroom()"
											class="float-right blue--text"
										>{{ priceInfo.currency ? priceInfo.currency.iso_code : "MYR" }} 0</span>
										<v-container
											v-if="orderCustomizations.mainColor"
											style="align-items:center; display:flex;"
											class="pl-0"
										>
											<div
												v-ripple
												style="height: 35px;width: 35px;"
												class="mr-3"
												:style="'background:'+orderCustomizations.mainColor.rgba +';  display:inline-block;'"
											>
											</div>
											<div 
												style="display:inline-block;"
											>
												{{ orderCustomizations.mainColor.name + ' - ' + orderCustomizations.mainColor.description }}
											</div>
										</v-container>
										
										<v-divider></v-divider>
									</v-col>	
								</v-row>
								<v-row 
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Contrast Color</span>
										<span
											v-if="$authUser.isCustomer() || $authUser.isShowroom()"
											class="float-right blue--text"
										>{{ priceInfo.currency ? priceInfo.currency.iso_code : "MYR" }} 0</span>
										<v-container
											v-if="orderCustomizations.secondaryColor"
											style="align-items:center; display:flex;"
											class="pl-0"
										>
											<div
												v-ripple
												style="height: 35px;width: 35px;"
												class="mr-3"
												:style="'background:'+orderCustomizations.secondaryColor.rgba +';  display:inline-block;'"
											>
											</div>
											<div 
												style="display:inline-block;"
											>
												{{ orderCustomizations.secondaryColor.name + ' - ' + orderCustomizations.secondaryColor.description }}
											</div>
										</v-container>
										<p
											v-else
											class="font-weight-black mt-3 pb-1"
										>
											- None
										</p>						
										<v-divider></v-divider>
									</v-col>	
								</v-row>
								<v-row 
									no-gutters
									class="pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Seam Type & Seam Color</span>
										<span
											v-if="$authUser.isCustomer() || $authUser.isShowroom()"
											class="float-right blue--text"
										>{{ priceInfo.currency ? priceInfo.currency.iso_code : "MYR" }} 0</span>
										<v-container
											v-if="orderCustomizations.seam"
											style="align-items:center; display:flex;"
											class="pl-0"
										>
											<v-row>
												<v-col cols="12" lg="3" class="pr-1">
													<div
														v-ripple
														style="height: 100px;width: 100px;"
													>
														<v-img :src="orderCustomizations.seam.image"></v-img>
													</div>
												</v-col>
												<v-col cols="12" lg="9">
													<v-row>
														<v-col class="pt-3">
															<span>Seam Type: </span>
															<span class="font-weight-black">{{ orderCustomizations.seam.title }}</span>
														</v-col>
													</v-row>
													<v-row v-if="orderCustomizations.seam.seamColor">
														<v-col class="pt-1">
															<span>Seam Color: </span>
															<span class="font-weight-black">{{ orderCustomizations.seam.seamColor.name + ' ' + orderCustomizations.seam.seamColor.description }}</span>
														</v-col>
													</v-row>
													<v-row v-if="orderCustomizations.seam.pipingColor">
														<v-col class="pt-1">
															<span>Piping Color: </span>
															<span class="font-weight-black">{{ orderCustomizations.seam.pipingColor.name + ' ' + orderCustomizations.seam.pipingColor.description }}</span>
														</v-col>
													</v-row>
												</v-col>
											</v-row>
										</v-container>
										<v-divider></v-divider>
									</v-col>	
								</v-row>
							</v-col>
							<v-col cols="12" md="5" class="py-0">
								<v-row 
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Logo</span>
										<span
											v-if="$authUser.isCustomer() || $authUser.isShowroom()"
											class="float-right blue--text"
										>{{ priceInfo.currency ? priceInfo.currency.iso_code : "MYR" }} 0</span>
										<v-container
											v-if="orderCustomizations.logo"
											style="align-items:center; display:flex;"
											class="pl-0"
										>
											<v-row>
												<v-col cols="3" class="pr-1">
													<div
														v-ripple
														style="height: 100px;width: 100px;"
													>
														<v-img :src="orderCustomizations.logo.img"></v-img>
													</div>
												</v-col>
												<v-col cols="12" lg="9">
													<v-row>
														<v-col class="pt-0 pb-4">
															<span>Size: </span>
															<span class="font-weight-black">{{ orderCustomizations.logo.height + 'cm x ' + orderCustomizations.logo.width +'cm' }}</span>
														</v-col>
													</v-row>
													<v-row>
														<v-col class="py-0">
															<span>File Name: </span>
															<span class="font-weight-black">{{ orderCustomizations.logo.name }}</span>
														</v-col>
													</v-row>
													<v-row>
														<v-col class="py-0">
															<v-container
																v-if="orderOptions.logoSeamColor"
																style="align-items:center; display:flex;"
																class="pl-0"
															>
																<div 
																	style="display:inline-block;"
																	class="mr-1"
																>
																	<span>Seam Color: </span>
																</div>
																<div 
																	style="display:inline-block;"
																	class="font-weight-black"
																>
																	{{ orderOptions.logoSeamColor.name }}
																</div>
																<div
																	v-ripple
																	style="height: 35px;width: 35px;"
																	class="mr-3 ml-3"
																	:style="'background:'+ orderOptions.logoSeamColor.rgba +';  display:inline-block;'"
																>
																</div>
															</v-container>
															<span v-else>Seam Color: </span>
														</v-col>
													</v-row>
												</v-col>
											</v-row>
										</v-container>
										<p
											v-else
											class="font-weight-black mt-3 pb-1"
										>
											- No
										</p>						
										<v-divider></v-divider>
									</v-col>
								</v-row>
								<v-row
									v-if="selectedSeatModel"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Airbag FB</span>
										<p class="mt-3">
											<span
												v-for="(item,i) in selectedSeatModel.airbag_styles_fbs"
												:key="i"
												class="font-weight-black" 
											>
												- {{ item.name }} <br>
											</span>
										</p>
										<v-divider></v-divider>
									</v-col>							
								</v-row>
								<v-row
									v-if="selectedSeatModel"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Airbag DT</span>
										<p class="mt-3">
											<span
												v-for="(item,i) in selectedSeatModel.airbag_styles_dts"
												:key="i"
												class="font-weight-black" 
											>
												- {{ item.name }} <br>
											</span>
										</p>
										<v-divider></v-divider>
									</v-col>								
								</v-row>
								<v-row
									v-if="selectedSeatModel"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>DT</span>
										<p class="mt-3">
											<span
												v-for="(item,i) in selectedSeatModel.dt_styles"
												:key="i"
												class="font-weight-black" 
											>
												- {{ item.name }} <br>
											</span>
										</p>
										<v-divider></v-divider>
									</v-col>
								</v-row>
								<v-row 
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Driver Seat Side</span>
										<p class="font-weight-black mt-3 pb-1">
											- {{ orderOptions.driverSeat ? orderOptions.driverSeat.name : '' }}
										</p>
										<v-divider></v-divider>
									</v-col>
								</v-row>
								<v-row
									v-if="!$authUser.isCustomer() || $authUser.isShowroom()"
									no-gutters
									class="overflow-auto pt-3"
								>
									<v-col 
										cols="12"
										class="my-2"
									>
										<span>Component</span>
										<p class="font-weight-black mt-3 pb-1">
											- {{ orderOptions.component ? orderOptions.component.name : 'N/A' }}
										</p>
										<v-divider></v-divider>
									</v-col>	
								</v-row>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" md="2">
						<span>Screenshots</span>
						<div style="height:40em;overflow-y:scroll;scrollbar-width: thin;">
							<v-img 
								:src="orderCustomizations.screenshot"
								class="screenshots mb-2 mt-2"
							>
							</v-img>
							<div v-for="(part_spec, index) in sortPartSpecs()" :key="index">
								<v-img 
									:src="part_spec.image"
									class="screenshots mb-2"
								>
								</v-img>
							</div>
						</div>
					</v-col>
				</v-row>
				<v-row 
					no-gutters
					class="overflow-auto pt-3"
				>
					<v-col 
						cols="12"
					>
						<span>Remarks</span>
						<br>
						<v-textarea
							v-model="orderOptions.remarks"
							:disabled="true"
							filled
							rows="2"
							row-height="3"
							label=""
							class="mt-3"
							no-resize
						></v-textarea>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<span>Part Specs</span>
						<span
							v-if="$authUser.isCustomer() || $authUser.isShowroom()"
							class="float-right blue--text"
						>{{pattern_sum}} Option(s) / Pattern: {{ priceInfo.currency ? priceInfo.currency.iso_code : "MYR" }} {{ priceInfo.pattern_price }}</span>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col
						v-for="(item, n) in sortPartSpecs()" 
						:key="n" 
						cols="12" md="5" 
						class="br ml-md-10 mt-5 d-flex flex-column"
					>
						<span class="font-weight-black mt-3 pb-1">{{ item.material_no + ' - ' + item.name }}</span>
						<v-container
							v-if="orderCustomizations.mainColor"
							style="align-items:center; display:flex;"
							class="pl-0 ml-5"
						>
							<div
								style="display:inline-block;"
								class="mr-sm-16"
							>
								<span>Color: </span>
							</div>
							<div
								v-ripple
								style="height: 35px;width: 35px;"
								class="mr-3 ml-5"
								:style="'background:'+ (item.color ? item.color.rgba : orderCustomizations.mainColor.rgba) +';  display:inline-block;'"
							>
							</div>
							<div 
								style="display:inline-block;"
							>
								{{ (item.color ? item.color.name : orderCustomizations.mainColor.name) + ' - ' + (item.color ? item.color.description :orderCustomizations.mainColor.description) }}
							</div>
						</v-container>
						<v-container
							v-if="item.special_material"
							style="align-items:center; display:flex;"
							class="pl-0 ml-5"
						>
							<div
								style="display:inline-block;"
								class="mr-2"
							>
								<span>Special Material:</span>
							</div>
							<div
								v-ripple
								style="height: 35px;width: 35px; display:inline-block;"
								class="mr-3"
							>
								<v-img :src="item.special_material.image"></v-img>
							</div>
							<div 
								style="display:inline-block;"
							>
								{{ item.special_material.name }}
							</div>
						</v-container>
						<v-container
							v-if="item.perforation"
							style="align-items:center; display:flex;"
							class="pl-0 ml-5"
						>
							<div
								style="display:inline-block;"
								class="mr-11"
							>
								<span>Perforation:</span>
							</div>
							<div
								v-ripple
								style="height: 35px;width: 35px; display:inline-block;"
								class="mr-3"
							>
								<v-img :src="item.perforation.image"></v-img>
							</div>
							<div 
								style="display:inline-block;"
							>
								{{ item.perforation.name }}
							</div>
						</v-container>
						<v-container
							v-if="item.psp"
							style="align-items:center; display:flex;"
							class="pl-0 ml-5"
						>
							<div
								style="display:inline-block;"
								class="mr-16"
							>
								<span>PSP:</span>
							</div>
							<div
								v-ripple
								style="height: 35px;width: 35px; display:inline-block;"
								class="mr-3 ml-7"
							>
								<v-img :src="item.psp.psp_details.image"></v-img>
							</div>
							<div 
								style="display:inline-block;"
							>
								{{ item.psp.psp_details.name }}
							</div>
						</v-container>
						<v-container
							v-if="item.pattern_stitching"
							style="align-items:center; display:flex;"
							class="pl-0 ml-5"
						>
							<div
								style="display:inline-block;"
								class="mr-1"
							>
								<span>Pattern Stitching:</span>
							</div>
							<div
								v-ripple
								style="height: 35px;width: 35px; display:inline-block;"
								class="mr-3"
							>
								<v-img :src="item.pattern_stitching.pattern_stitching_details.img"></v-img>
							</div>
							<div 
								style="display:inline-block;"
							>
								{{ item.pattern_stitching.pattern_stitching_details.name }}
							</div>
						</v-container>
						<v-container
							v-if="item.digital_punching"
							style="align-items:center; display:flex;"
							class="pl-0 ml-5"
						>
							<div
								style="display:inline-block; margin-right: 7px"
							>
								<span>Digital Punching:</span>
							</div>
							<div
								v-ripple
								style="height: 35px;width: 35px; display:inline-block;"
								class="mr-3"
							>
								<v-img :src="item.digital_punching.digital_punching_details.image"></v-img>
							</div>
							<div 
								style="display:inline-block;"
							>
								{{ item.digital_punching.digital_punching_details.name }}
							</div>
						</v-container>
						<v-container
							v-if="(item.psp || item.digital_punching || item.pattern_stitching) && getPspColor(item)"
							style="align-items:center; display:flex;"
							class="pl-0 ml-5"
						>
							<div
								style="display:inline-block; margin-right: 6px;"
							>
								<span>PSP Seam Color:</span>
							</div>
							<div
								v-ripple
								style="height: 35px;width: 35px;"
								class="mr-3"
								:style="'background:'+ getPspColor(item)['rgba'] +';  display:inline-block;'"
							>
							</div>
							<div 
								style="display:inline-block;"
							>
								{{ getPspColor(item)['name'] + ' - ' + getPspColor(item)['description'] }}
							</div>
						</v-container>
						<v-spacer></v-spacer>
						<v-divider></v-divider>
					</v-col>
				</v-row>		
				<v-row justify="end">
					<v-col cols="12" md="3">
						<v-btn
							block
							depressed
							color="primary"
							class="text-capitalize secondary--text radius-10 mb-3"
							@click="()=>{this.$router.push('/new-order/payment-delivery')}"
						>
							Check Out
						</v-btn>
					</v-col>
				</v-row>
			</div>
		</v-container>
	</div>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"
import { searchMixin } from "@/mixins/SearchMixin"
import { mapGetters, mapActions } from "vuex"

export default {
	components:{

	},
	mixins: [
		resourceMixin,
		searchMixin,
		errorHandlerMixin
	],
	props:{
	},
	data(){
		return {
		}
	},
	computed:{
		...mapGetters(["orderCustomizations", "orderOptions", "partSpecs", "priceInfo", "selectedSeatModel", "selectionCounter"]),
		pattern_sum()
		{
			if(this.selectionCounter)
				return this.selectionCounter.special_material.counter + this.selectionCounter.perforation.counter + this.selectionCounter.psp.counter + this.selectionCounter.pf.counter + this.selectionCounter.pd.counter

			return null 
		}
	},
	activated(){
		if(Object.keys(this.orderCustomizations).length === 0)
			this.backToCustomizationPage(true)

		this.scrollToTop()
	},
	created(){
	},
	methods:{
		...mapActions(["setIsCustomization"]),
		sortPartSpecs()
		{
			return this.partSpecs.slice().sort(function(a, b){
				return (a.material_no > b.material_no) ? 1 : -1;
			});
		},
		getPspColor(item)
		{
			if(item.psp)
			{
				return item.psp.color ?? this.orderCustomizations.pspColor
			}
			else if(item.pattern_stitching)
			{
				return item.pattern_stitching.color ?? this.orderCustomizations.pspColor
			}
			else if(item.digital_punching)
			{
				return item.digital_punching.color ?? this.orderCustomizations.pspColor
			}
		},
		backToCustomizationPage(status)
		{
			this.setIsCustomization(status)
			this.$router.push('/new-order/customization')
		},
		scrollToTop() {
			window.scrollTo(0,0);
		}
	}
}
</script>
<style scoped>
.screenshots {
  border: 2px solid #555;
}
</style>
